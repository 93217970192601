<template>
  <b-card no-body class="rounded-0 mb-1">
    <b-card-header
      header-tag="header"
      class="p-3 border-bottom-0 bg-light d-flex justify-content-between"
      role="tab"
      v-b-toggle="'accordion-' + index"
    >
      <div>
        <i class="fad fa-sort-up draggable-sort-item" />
        <span class="pl-2 font-weight-light h6">
          {{ item[defaultDil].baslik }}
        </span>
      </div>
      <b-button variant="danger" size="sm" @click="$emit('remove', index)">
        <i class="fad fa-trash"></i>
      </b-button>
    </b-card-header>
    <b-collapse
      :id="'accordion-' + index"
      accordion="my-accordion"
      role="tabpanel"
    >
      <b-card-body>
        <b-card-text>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Başlık">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  ref="baslik"
                  v-model="item[defaultDil].baslik"
                  aria-describedby="input-1-live-feedback"
                  @input="handlerItemBaslikChange"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="İcon">
                <b-form-input size="lg" class="rounded-0" v-model="item.icon" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="`Ön Açıklama ${onAciklamaLenght} / 300`"
                description="Ön Açıklama 300 karakteri geçmemelidir"
              >
                <b-form-textarea
                  class="rounded-0"
                  ref="on_aciklama"
                  v-model="item[defaultDil].on_aciklama"
                  aria-describedby="input-1-live-feedback"
                  @input="handlerOnAcklamaChange"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" class="">
              <b-form-group label="Açıklama">
                <tinymce
                  ref="editor"
                  :id="`editor_${index}`"
                  v-model="item[defaultDil].aciklama"
                  :other_options="{ height: 350 }"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Slug (Seflink)"
                description="Bu link yapısı bir kere oluşturulacaktır. Düzenleme yaptığınız takdirde Google,Yandex vb siteler indexlemeyi ortalama 1 ay için de tekrardan bu sayfayı indexleme alacaktır."
              >
                <b-input-group>
                  <b-form-input
                    size="lg"
                    class="rounded-0"
                    v-model="item[defaultDil].slug"
                    :disabled="!slug_duzenle"
                  />
                  <b-input-group-append v-if="form.k_no">
                    <b-button
                      squared
                      variant="outline-success"
                      @click="slug_duzenle = !slug_duzenle"
                    >
                      <i class="fad fa-pencil"></i>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Poziyon">
                <v-select
                  v-model="item.pozisyon"
                  :options="pozisyonItem"
                  :reduce="(durum) => durum.id"
                  label="title"
                  :clearable="false"
                  class="invoice-filter-select d-block select-size-lg"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Kapak Resmi">
                <v-select
                  v-model="item.kapak"
                  :options="form.gorseller"
                  :reduce="(durum) => durum.gorsel"
                  :clearable="false"
                  class="invoice-filter-select d-block select-size-lg"
                >
                  <template slot="selected-option" slot-scope="option">
                    <img
                      :src="handlerImageShow(option.gorsel)"
                      style="width: 50px"
                    />
                    {{ option.gorsel }}
                  </template>
                  <template slot="option" slot-scope="option">
                    <img
                      :src="handlerImageShow(option.gorsel)"
                      style="width: 50px"
                    />
                    {{ option.gorsel }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Gideceği Url">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  ref="url"
                  v-model="item[defaultDil].url"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Video">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  ref="video"
                  v-model="item[defaultDil].video"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Taglar">
                <b-form-tags
                  size="lg"
                  v-model="item[defaultDil].keywords"
                  input-id="tags-basic"
                  class="mb-2 rounded-0"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import store from "@/store";
import {
  ref,
  toRefs,
  computed,
  onMounted,
  defineComponent,
} from "@vue/composition-api";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import slugify from "slugify";
import vSelect from "vue-select";
import tinymce from "tinymce";
export default defineComponent({
  components: { vSelect },
  props: {
    form: {
      type: Object,
      requried: true,
    },
    index: {
      type: Number,
      requried: true,
    },
    item: {
      type: Object,
      requried: true,
    },
    defaultDil: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const expo = {};
    expo.onAciklamaLenght = ref(0);
    expo.slug_duzenle = ref(false);

    const { form, item, index, defaultDil } = toRefs(props);

    expo.pozisyonItem = ref([
      {
        id: "sag",
        title: "Sağda Yasla",
      },
      {
        id: "sol",
        title: "Sola Yasla",
      },
      {
        id: "orta",
        title: "Ortada Göster",
      },
    ]);

    expo.handlerImageShow = (val) => {
      if (val != undefined) {
        const fileName = store.getters.getUserFirma.db;
        const base_url =
          process.env.NODE_ENV === "development"
            ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
            : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);

        const url = `${base_url}uploads/${fileName}/sayfa/${val}`;
        return url;
      }
    };

    expo.handlerItemBaslikChange = (event) => {
      const slug = slugify(event, { lower: true });
      if (!form.value.k_no || expo.slug_duzenle.value)
        item.value[defaultDil.value].slug = slug;
    };

    expo.handlerOnAcklamaChange = (event) => {
      expo.onAciklamaLenght.value = event.length > 0 ? event.length : 0;
    };

    onMounted(() => {
      tinymce
        .get("editor_" + index.value)
        .setContent(item.value[defaultDil.value].aciklama);
    });

    return { ...expo };
  },
});
</script>

<style scoped></style>
