<template>
  <div>
    <b-row>
      <b-col cols="6">
        <div class="d-flex justify-content-between align-items-center py-1">
          <label for="statu" class="h6">Banner Alanını Göster</label>
          <b-form-checkbox
            :checked="form.ayar.banner"
            v-model="form.ayar.banner"
            switch
            size="lg"
          />
        </div>
      </b-col>
      <b-col cols="6">
        <div class="d-flex justify-content-between align-items-center py-1">
          <label for="statu" class="h6">Bread Crumbs Alanı Göster</label>
          <b-form-checkbox
            :checked="form.ayar.bread_crumbs"
            v-model="form.ayar.bread_crumbs"
            switch
            size="lg"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <div class="d-flex justify-content-between align-items-center py-1">
          <label for="statu" class="h6">Video Gösterimi</label>
          <b-form-checkbox
            :checked="form.ayar.video_gosterim"
            v-model="form.ayar.video_gosterim"
            switch
            size="lg"
          />
        </div>
      </b-col>
      <b-col cols="6">
        <div class="d-flex justify-content-between align-items-center py-1">
          <label for="statu" class="h6">Misafir Yorum Göster</label>
          <b-form-checkbox
            :checked="form.ayar.misafir_yorumlari"
            v-model="form.ayar.misafir_yorumlari"
            switch
            size="lg"
          />
        </div>
      </b-col>
      <b-col cols="6">
        <div class="d-flex justify-content-between align-items-center py-3">
          <label for="statu" class="h6">Ürünlerden Bazıları Göster</label>
          <b-form-checkbox
            :checked="form.ayar.urunlerden_bazilari"
            v-model="form.ayar.urunlerden_bazilari"
            switch
            size="lg"
          />
        </div>
      </b-col>
      <b-col cols="6">
        <div class="d-flex justify-content-between align-items-center py-3">
          <label for="statu" class="h6">Abone Ol Göster</label>
          <b-form-checkbox
            :checked="form.ayar.abone_ol"
            v-model="form.ayar.abone_ol"
            switch
            size="lg"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6">
        <b-form-group label-cols="5" label="Sayfa Format">
          <v-select
            v-model="form.ayar.sayfa_format"
            :options="sayfaFormat"
            :reduce="(sayfa) => sayfa.id"
            label="title"
            :clearable="false"
            class="invoice-filter-select d-block select-size-lg"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label-cols="5" label="Sayfa Pozisyon">
          <v-select
            v-model="form.ayar.pozisyon"
            :options="sayfaPozisyon"
            :reduce="(sayfa) => sayfa.id"
            label="title"
            :clearable="false"
            class="invoice-filter-select d-block select-size-lg"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="pt-1">
      <b-col cols="12" md="6" lg="6">
        <b-button
          squared
          block
          type="submit"
          variant="primary"
          @click="$emit('onSubmit', true)"
        >
          <i class="fad fa-save" />
          <span class="align-middle">Güncelle</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  components: {
    vSelect,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const expo = {};
    expo.sayfaFormat = ref([
      { id: "yazi", title: "Sadece Yazı" },
      { id: "yazi_resim", title: "Yazı ve Resim" },
      { id: "yazi_galeri", title: "Yazı ve Galeri" },
      { id: "collapse_sayfa", title: "Collapse Sayfa" },
      { id: "tap_sayfa", title: "Tab Sayfa" },
    ]);

    expo.sayfaPozisyon = ref([
      { id: "sag", title: "Sağ" },
      { id: "sol", title: "Sol" },
      { id: "orta", title: "Orta" },
    ]);

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
