<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" autocomplete="off">
      <b-row>
        <b-col cols="12" md="8">
          <b-card class="rounded-0">
            <b-row>
              <b-col cols="12">
                <validation-provider
                  name="Başlık"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group label="Başlık">
                    <b-form-input
                      size="lg"
                      class="rounded-0"
                      ref="baslik"
                      v-model="form.icerik[defaultDil].baslik"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                      @input="handlerBaslikChange"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" v-if="form.type">
                <b-form-group
                  label="Slug (Seflink)"
                  description="Bu link yapısı bir kere oluşturulacaktır. Düzenleme yaptığınız takdirde Google,Yandex vb siteler indexlemeyi ortalama 1 ay için de tekrardan bu sayfayı indexleme alacaktır."
                >
                  <b-input-group>
                    <b-form-input
                      size="lg"
                      class="rounded-0"
                      v-model="form.icerik[defaultDil].slug"
                      :disabled="!slug_duzenle"
                    />
                    <b-input-group-append v-if="form.k_no">
                      <b-button
                        squared
                        variant="outline-success"
                        @click="slug_duzenle = !slug_duzenle"
                      >
                        <i class="fad fa-pencil"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="!form.type">
              <b-col cols="12">
                <validation-provider
                  name="Ön Açıklama"
                  :rules="{ required: !form.type, max: 300 }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    :label="`Ön Açıklama ${onAciklamaLenght} / 300`"
                    description="Ön Açıklama 300 karakteri geçmemelidir"
                  >
                    <b-form-textarea
                      class="rounded-0"
                      ref="on_aciklama"
                      v-model="form.icerik[defaultDil].on_aciklama"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                      @input="handlerOnAcklamaChange"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" class="">
                <validation-provider
                  name="Açıklama"
                  :rules="{ required: !form.type }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    label="Açıklama"
                    :class="{ 'is-invalid': !!validationContext.errors[0] }"
                  >
                    <!-- <ckeditor
                      :editor="editor"
                      :config="editorConfig"
                      :class="{ 'is-invalid': !!validationContext.errors[0] }"
                      v-model="form.icerik[defaultDil].aciklama"
                    ></ckeditor> -->
                    <tinymce
                      id="d1"
                      v-model="form.icerik[defaultDil].aciklama"
                      :class="{ 'is-invalid': !!validationContext.errors[0] }"
                      :other_options="{ height: 350 }"
                    ></tinymce>
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Slug (Seflink)"
                  description="Bu link yapısı bir kere oluşturulacaktır. Düzenleme yaptığınız takdirde Google,Yandex vb siteler indexlemeyi ortalama 1 ay için de tekrardan bu sayfayı indexleme alacaktır."
                >
                  <b-input-group>
                    <b-form-input
                      size="lg"
                      class="rounded-0"
                      v-model="form.icerik[defaultDil].slug"
                      :disabled="!slug_duzenle"
                    />
                    <b-input-group-append v-if="form.k_no">
                      <b-button
                        squared
                        variant="outline-success"
                        @click="slug_duzenle = !slug_duzenle"
                      >
                        <i class="fad fa-pencil"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Ozel Url Ekle">
                  <b-form-input
                    size="lg"
                    class="rounded-0"
                    ref="video"
                    v-model="form.icerik[defaultDil].link"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Video">
                  <b-form-input
                    size="lg"
                    class="rounded-0"
                    ref="video"
                    v-model="form.icerik[defaultDil].video"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Taglar">
                  <b-form-tags
                    size="lg"
                    v-model="form.icerik[defaultDil].keywords"
                    input-id="tags-basic"
                    class="mb-2 rounded-0"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col cols="12">
                <draggable
                  v-model="form.ozel_icerik"
                  tag="ul"
                  class="list-group"
                  handle=".draggable-sort-item"
                >
                  <IcerikOzelItem
                    v-for="(item, index) in form.ozel_icerik"
                    :key="index"
                    :index="index"
                    :item="item"
                    :defaultDil="defaultDil"
                    :form="form"
                    @remove="handlerRemoveItem($event)"
                  />
                </draggable>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12" md="4">
          <b-card class="rounded-0">
            <b-row>
              <b-col cols="12">
                <b-form-group label-cols="5" label="Özellestir">
                  <b-form-checkbox
                    class="float-right"
                    :checked="form.type"
                    v-model="form.type"
                    switch
                    size="lg"
                    @change="$emit('handlerOzellestir', $event)"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" v-if="form.type">
                <b-form-group label-cols="5" label="Özellestir İçerik Ekle">
                  <b-button
                    variant="primary"
                    size="sm"
                    class="float-right"
                    @click="$emit('handlerAdd', true)"
                  >
                    <i class="fad fa-plus"></i> İçerik Ekle
                  </b-button>
                </b-form-group>
              </b-col>
              <b-col cols="12" v-show="diller.length != 1">
                <b-form-group label-cols="5" label="Dil">
                  <v-select
                    v-model="defaultDil"
                    :options="diller"
                    :reduce="(diller) => diller.lang"
                    label="title"
                    :clearable="false"
                    class="invoice-filter-select d-block select-size-lg"
                    @input="handlerLocaleChange($event)"
                    :disabled="!form._id"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label-cols="5" label="Yayın Durumu">
                  <v-select
                    v-model="form.statu"
                    :options="durumlar"
                    :reduce="(durum) => durum.id"
                    label="title"
                    :clearable="false"
                    class="invoice-filter-select d-block select-size-lg"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label-cols="5" label="Anasayfa Gösterim">
                  <v-select
                    v-model="form.anasayfa"
                    :options="durumlar"
                    :reduce="(durum) => durum.id"
                    label="title"
                    :clearable="false"
                    class="invoice-filter-select d-block select-size-lg"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label-cols="5" label="Kategoriler">
                  <v-select
                    v-model="form.kategori_k_no"
                    :options="kategoriler"
                    :reduce="(kategori) => kategori.k_no"
                    label="baslik"
                    :clearable="true"
                    class="invoice-filter-select d-block select-size-lg"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <div
                  class="d-flex justify-content-between align-items-center py-1"
                >
                  <label for="statu" class="h6">Seo Optimize</label>
                  <b-form-checkbox
                    :checked="form.seo"
                    v-model="form.seo"
                    switch
                    size="lg"
                  />
                </div>
              </b-col>
              <b-col cols="12">
                <b-button
                  size="lg"
                  :variant="form.k_no == null ? 'success' : 'warning'"
                  type="submit"
                  block
                  class="rounded-0 mr-1"
                >
                  <i class="fad fa-save" />
                  <span class="align-middle pl-2">
                    {{ form.k_no == null ? "KAYDET" : "GÜNCELLE" }}
                  </span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import store from "@/store";
import vSelect from "vue-select";
import draggable from "vuedraggable";
import { defineComponent, ref, toRefs } from "@vue/composition-api";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import slugify from "slugify";
import IcerikOzelItem from "./IcerikOzelItem.vue";
export default defineComponent({
  components: {
    vSelect,
    draggable,
    IcerikOzelItem,
  },
  props: {
    kategoriler: {
      type: Array,
      required: true,
    },
    diller: {
      type: Array,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};

    const { form } = toRefs(props);

    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.onAciklamaLenght = ref(0);
    expo.slug_duzenle = ref(false);
    expo.editor = ref(ClassicEditor);

    expo.editorConfig = ref({
      toolbar: {
        items: [
          "exportPDF",
          "exportWord",
          "|",
          "findAndReplace",
          "selectAll",
          "|",
          "heading",
          "|",
          "bold",
          "italic",
          "strikethrough",
          "underline",
          "code",
          "subscript",
          "superscript",
          "removeFormat",
          "|",
          "bulletedList",
          "numberedList",
          "todoList",
          "|",
          "outdent",
          "indent",
          "|",
          "undo",
          "redo",
          "-",
          "fontSize",
          "fontFamily",
          "fontColor",
          "fontBackgroundColor",
          "highlight",
          "|",
          "alignment",
          "|",
          "link",
          "uploadImage",
          "blockQuote",
          "insertTable",
          "mediaEmbed",
          "codeBlock",
          "htmlEmbed",
          "|",
          "specialCharacters",
          "horizontalLine",
          "pageBreak",
          "|",
          "textPartLanguage",
          "|",
          "sourceEditing",
        ],
        shouldNotGroupWhenFull: true,
      },
      // toolbar: [
      //   "heading",
      //   "bulletedList",
      //   "numberedList",
      //   "fontFamily",
      //   "textColor",
      //   "FontSize",
      //   "undo",
      //   "redo",
      // ],
      // toolbar: [
      //   {
      //     name: "clipboard",
      //     items: [
      //       "Cut",
      //       "Copy",
      //       "Paste",
      //       "PasteText",
      //       "PasteFromWord",
      //       "-",
      //       "Undo",
      //       "Redo",
      //     ],
      //   },
      //   {
      //     name: "basicstyles",
      //     items: ["Bold", "Italic", "Strike", "RemoveFormat"],
      //   },
      //   {
      //     name: "styles",
      //     items: ["Styles", "Format", "FontSize", "TextColor", "BGColor"],
      //   },

      //   { name: "editing", items: ["Scayt"] },
      //   { name: "links", items: ["Link", "Unlink", "Anchor"] },
      //   {
      //     name: "insert",
      //     items: ["Image", "Table", "HorizontalRule", "SpecialChar"],
      //   },

      //   {
      //     name: "paragraph",
      //     items: [
      //       "NumberedList",
      //       "BulletedList",
      //       "-",
      //       "Outdent",
      //       "Indent",
      //       "-",
      //       "Blockquote",
      //     ],
      //   },

      //   { name: "tools", items: ["Maximize"] },
      //   { name: "document", items: ["Source"] },
      // ],
    });

    expo.durumlar = ref([
      { id: true, title: "Evet" },
      { id: false, title: "Hayır" },
    ]);

    expo.handlerOnAcklamaChange = (event) => {
      expo.onAciklamaLenght.value = event.length > 0 ? event.length : 0;
    };

    expo.handlerBaslikChange = (event) => {
      const slug = slugify(event, { lower: true });
      if (!form.value.k_no || expo.slug_duzenle.value)
        form.value.icerik[expo.defaultDil.value].slug = slug;
    };

    expo.handlerRemoveItem = (index) => {
      form.value.ozel_icerik.splice(index, 1);
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit("onSubmit", true);
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
