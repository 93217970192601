<template>
  <b-tabs content-class="mt-3">
    <b-tab title="İçerik" active>
      <Icerik
        :diller="diller"
        :kategoriler="kategoriler"
        :form="form"
        @form="form = $event"
        @onSubmit="onSubmit"
        @handlerOzellestir="handlerOzellestir"
        @handlerOzelIcerikUret="createOzelIcerikDilUret"
        @createOzelIcerikDilSil="createOzelIcerikDilSil($event)"
        @handlerAdd="createOzelIcerikDilUret"
      />
    </b-tab>
    <b-tab title="Fotoğraflar" :disabled="!form.k_no">
      <Fotograflar
        :form="form"
        @form="form = $event"
        @show="$emit('show', $event)"
      />
    </b-tab>
    <b-tab title="Diğer Ayarlar" :disabled="!form.k_no">
      <SayfaAyarlar :form="form" @onSubmit="onSubmit" />
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch,
  beforeRouteLeave,
} from "@vue/composition-api";
import Icerik from "./component/Icerik.vue";
import Fotograflar from "./component/Fotograflar.vue";
import SayfaAyarlar from "./component/SayfaAyarlar.vue";
import store from "@/store";
import { useRouter } from "@/libs/utils";
import { useToast } from "vue-toastification/composition";
import Swal from "sweetalert2";
import { langs } from "@/libs/languages";
export default defineComponent({
  components: {
    Icerik,
    Fotograflar,
    SayfaAyarlar,
  },
  setup(_, context) {
    const expo = {};
    const { route, router } = useRouter();
    const toast = useToast();
    expo.kategoriler = ref([]);
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    const routeChange = computed(() => route.value);

    const handlerFetchAllData = async () => {
      await store.dispatch("kategoriListele", { statu: true });
      const kat = store.getters.getKategoriler;
      kat.forEach((el) => {
        expo.kategoriler.value.push({
          k_no: el.k_no,
          baslik: el.icerik[expo.defaultDil.value].baslik,
        });
      });
    };

    expo.form = ref({
      k_no: null,
      type: false,
      icerik: {},
      type: false,
      ozel_icerik: [],
      video: null,
      statu: true,
      anasayfa: false,
      kategori_k_no: null,
      banner: null,
      taglar: [],
      ayar: {
        banner: true,
        bread_crumbs: true,
        sayfa_format: "yazi_resim",
        pozisyon: "sag", //sag,sol,orta
        misafir_yorumlari: false,
        urunlerden_bazilari: false,
        abone_ol: false,
      },
      gorseller: [],
    });

    const resetForm = () => {
      expo.form.value = {
        k_no: null,
        type: false,
        icerik: {},
        ozel_icerik: [],
        statu: true,
        anasayfa: false,
        kategori_k_no: null,
        banner: null,
        taglar: [],
        ayar: {
          banner: true,
          bread_crumbs: true,
          sayfa_format: "yazi_resim",
          misafir_yorumlari: false,
          urunlerden_bazilari: false,
          abone_ol: false,
        },
        gorseller: [],
        seo: true,
      };
    };

    const createIcerikDilUret = () => {
      langs.forEach((dil) => {
        expo.form.value.icerik[dil.lang] = {
          baslik: null,
          on_aciklama: null,
          aciklama: "",
          video: null,
          keywords: [],
          slug: null,
          link: null,
        };
      });
    };

    createIcerikDilUret();

    expo.createOzelIcerikDilUret = () => {
      let icerik = {};
      langs.forEach((dil) => {
        icerik[dil.lang] = {
          baslik: null,
          on_aciklama: null,
          aciklama: "",
          video: null,
          slug: null,
          keywords: [],
          link: null,
        };
      });
      expo.form.value.ozel_icerik.push({
        ...icerik,
        icon: null,
        kapak: null,
        pozisyon: "sag",
      });
    };

    expo.createOzelIcerikDilSil = (index) => {
      expo.form.value.ozel_icerik.splice(index, 1);
    };

    expo.handlerOzellestir = () => {
      if (expo.form.value.type == true) {
        if (expo.form.value.k_no == null) {
          expo.createOzelIcerikDilUret();
        }
      }
    };

    onMounted(async () => {
      context.emit("show", true);
      await handlerFetchAllData();
      await handlerUpdated();
      context.emit("show", false);
    });

    const handlerUpdated = async () => {
      const k_no = route.value.params.k_no;
      if (k_no != null) {
        // const sayfalar = store.getters.getSayfalar;
        // let sayfa = {};
        // if (sayfalar.length > 0) {
        //   sayfa = sayfalar.find((x) => x.k_no === k_no);
        //   expo.form.value = { ...sayfa };
        // } else {
        await store.dispatch("sayfaFindOne", k_no).then((res) => {
          console.log(res);
          expo.form.value = { ...res };
        });
        // }
      }
      context.emit("backTo", true);
    };

    expo.onSubmit = () => {
      context.emit("show", true);
      const baseUrl =
        expo.form.value.k_no == null ? "sayfaEkle" : "sayfaGuncelle";
      store
        .dispatch(baseUrl, expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success(
              !expo.form.value.k_no ? "Ekleme Başarılı" : "Güncelleme Başarılı",
              { position: "bottom-left" }
            );
            expo.form.value = res.data.data;
            if (
              expo.form.value.k_no != null &&
              route.value.name != "sayfa-guncelle"
            ) {
              router.push({
                name: "sayfa-guncelle",
                params: { k_no: expo.form.value.k_no },
              });
            }
            context.emit("show", false);
          }
        })
        .catch((err) => {
          if (err.success === false) {
            toast.success(err.message, { position: "bottom-left" });
          }
        });
    };

    // beforeRouteLeave((to, from) => {
    //   console.log(to);
    // });
    watch(routeChange, (val) => {
      if (Object.keys(val.params).length == 0) {
        resetForm();
        createIcerikDilUret();
      }
    });

    return { ...expo };
  },

  beforeRouteLeave(to, from, next) {
    if (this.form.k_no == null) {
      if (
        this.form.icerik[this.defaultDil].baslik != null ||
        this.form.icerik[this.defaultDil].on_aciklama != null ||
        this.form.icerik[this.defaultDil].aciklama.length > 0
      ) {
        Swal.fire({
          title: "Uyarı",
          text: "Kaydedilmemiş veri var. Çıkmak istediğinizden eminmisiniz ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        }).then((result) => {
          if (result.isConfirmed == true) {
            next();
          } else {
            this.$emit("backTo", true);
          }
        });
      } else {
        next();
      }
    } else {
      next();
    }
  },
});
</script>

<style lang="scss" scoped></style>
